import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import Partner from './Partner';
import BecomePartner from './BecomePartner';
import styles from './styles.module.sass';

const Partners = () => (
  <StaticQuery
   query={graphql`
    query {
      au2cloud: imageSharp(fluid: {
        originalName: { eq: "About-Us-Logo-Au2Cloud.png" }
      }) {
        fluid(maxWidth: 440, maxHeight: 200) {
          ...GatsbyImageSharpFluid
        }
      }
      caruso: imageSharp(fluid: {
        originalName: {eq: "About-Us-Logo-Caruso.png"}
      }) {
        fluid(maxWidth: 440, maxHeight: 200) {
          ...GatsbyImageSharpFluid
        }
      }
      ftz: imageSharp(fluid: {
        originalName: {eq: "About-Us-Logo-FTZ.png"}
      }) {
        fluid(maxWidth: 440, maxHeight: 200) {
          ...GatsbyImageSharpFluid
        }
      }
      munic: imageSharp(fluid: {
        originalName: {eq: "About-Us-Logo-Munic.jpg"}
      }) {
        fluid(maxWidth: 440, maxHeight: 200) {
          ...GatsbyImageSharpFluid
        }
      }
      otonomo: imageSharp(fluid: {
        originalName: {eq: "About-Us-Logo-Otonomo.png"}
      }) {
        fluid(maxWidth: 440, maxHeight: 200) {
          ...GatsbyImageSharpFluid
        }
      }
      traffilog: imageSharp(fluid: {
        originalName: {eq: "About-Us-Logo-Traffilog.png"}
      }) {
        fluid(maxWidth: 440, maxHeight: 200) {
          ...GatsbyImageSharpFluid
        }
      }
      roadfox: imageSharp(fluid: {
        originalName: {eq: "About-Us-Logo-Roadfox.png"}
      }) {
        fluid(maxWidth: 440, maxHeight: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `}
  render={({
    au2cloud,
    caruso,
    ftz,
    munic,
    otonomo,
    traffilog,
    roadfox
  }) => (
    <SectionWrapper id={i18n('_hash:partners')}
      justify='space-between'
      className={styles.container}>
      <Grid item container
        xs={12} sm={12} md={12} lg={12}
        alignContent='center'
        className={styles.titleContainer}>
        <Typography variant='h3'
          color='dark'
          align='center'
          weight='bold'
          className={styles.title}>
          {i18n('Commercial partners')}
        </Typography>
        <Typography variant='subtitle1'
          weight='light'
          align='center'
          className={styles.footnote}>
          {i18n('We are here for those who refuse to settle, who believe in the future of connected mobility. Because today\'s world deserves a partner that is able to adapt fast, recognizes the needs of different types of vehicle users, and is ready to push into new exciting spaces.')}
        </Typography>
      </Grid>
      <Grid container className={styles.partnersContainer}>
        <Partner image={au2cloud.fluid}
          subtext={i18n('Repair shop concepts')}
          name='Au2parts - au2cloud'
          url='https://au2parts.dk/produkter/au2cloud/'
          wrapperClass={styles.partnerWrapperAu2cloud}
          containerClass={styles.partnerContainerAu2cloud}
          textClass={styles.buttonTextAu2cloud}
          imageContainerClass={styles.partnerImageContainerAu2cloud}/>
        <Partner image={caruso.fluid}
          subtext={i18n('In-vehicle data into complete software solutions')}
          name='Caruso'
          url='https://www.caruso-dataplace.com/'
          wrapperClass={styles.partnerWrapperCaruso}
          containerClass={styles.partnerContainerCaruso}
          textClass={styles.buttonTextFtz}
          imageContainerClass={styles.partnerImageContainerCaruso}/>
        <Partner image={ftz.fluid}
          subtext={i18n('Repair shop concepts')}
          name='FTZ - FTZ telematik'
          url='https://www.ftz.dk/produkter/serviceydelser/telematik.aspx'
          wrapperClass={styles.partnerWrapperFtz}
          containerClass={styles.partnerContainerFtz}
          textClass={styles.buttonTextFtz}
          imageContainerClass={styles.partnerImageContainerFtz}/>
        <Partner image={munic.fluid}
          subtext={i18n('Edge computing & off-the-shelf connected services')}
          name='Munic'
          url='https://www.munic.io/'
          wrapperClass={styles.partnerWrapperMunic}
          containerClass={styles.partnerContainerMunic}
          textClass={styles.buttonTextMunic}
          imageContainerClass={styles.partnerImageContainerMunic}/>
        <Partner image={otonomo.fluid}
          subtext={i18n('Car data and connected car services as a complete package')}
          name='Otonomo'
          url='https://otonomo.io/'
          wrapperClass={styles.partnerWrapperOtonomo}
          containerClass={styles.partnerContainerOtonomo}
          textClass={styles.buttonTextOtonomo}
          imageContainerClass={styles.partnerImageContainerOtonomo}/>
        <Partner image={traffilog.fluid}
          subtext={i18n('Fleet management')}
          name='Traffilog Nordic'
          url='https://www.traffilog.com/'
          wrapperClass={styles.partnerWrapperTraffilog}
          containerClass={styles.partnerContainerTraffilog}
          textClass={styles.buttonTextTraffilog}
          imageContainerClass={styles.partnerImageContainerTraffilog}/>
        <Partner image={roadfox.fluid}
          subtext={i18n('Serviced fleet management')}
          name='P.S. Automotive - Roadfox'
          url='https://www.roadfox.eu/'
          wrapperClass={styles.partnerWrapperRoadfox}
          containerClass={styles.partnerContainerRoadfox}
          textClass={styles.buttonTextRoadfox}
          imageContainerClass={styles.partnerImageContainerRoadfox}/>
        <BecomePartner/>
      </Grid>
    </SectionWrapper>
  )}/>
);

export default Partners;