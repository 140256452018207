import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import { Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';


const Partner = ({
  wrapperClass,
  containerClass,
  imageContainerClass,
  textClass,
  image,
  name,
  subtext,
  url
}) => {
  const largeScreen = useMediaQuery('@media(min-width: 960px)'); //This one should be done properly and use useMediaQuery(theme => theme.breakpoints.up('md'));
  return <Grid item
    xs={12} sm={6} md={4} lg={4} xl={4}
    className={clsx(
      styles.partnerWrapper,
      wrapperClass
    )}>
    <Grid item container className={clsx(
        styles.partnerContainer,
        containerClass
      )}
      alignContent='center'
      alignItems='center'>
      <Grid item xs={12} sm={12} md={12} className={styles.imageContainer}>
      <a href={url} target="_blank" rel="noreferrer">
        <Img className={clsx(
            styles.imageContainer,
            imageContainerClass
          )}
          objectFit='contain'
          objectPosition={largeScreen ? 'left top' : 'center top'}
          imgStyle={{ objectFit: 'contain' }}
          fluid={image}/>
        </a>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className={styles.textContainer}>
        <Typography variant='h5' align='left'
          className={clsx(
            styles.text,
            styles.textName
          )}>
          <a href={url} target="_blank" rel="noreferrer" className={styles.partnerLink}>{name}</a>
        </Typography>
        <Typography variant='subtitle2' weight='light' align='left'
          className={styles.text}>
          {subtext}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
};

export default Partner;