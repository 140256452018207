import React from 'react';
import Content from '../views/AboutUs';

export default ({location}) => <Content location={location} seo={{
  title: "Den førende udbyder af connected car tjenester i Danmark og Europa.",
  lang:'da-DK',
  description: "OBI+ er en teknologivirksomhed, der leverer en applikationsplatform for connected car tjenester, der skaber værdi, leverer indsigt og muliggør vækst.",
  meta: [{
    name: 'keywords',
    content: 'Connected Car tjenester',
  }]
}}/>