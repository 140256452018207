import React from 'react';
import { Grid } from '@material-ui/core';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} container justify='center' alignContent='center' alignItems='center'
    className={styles.wrapper}>
    <Grid item container className={styles.container}  justify='center' alignContent='center' direction='column'>
      <Typography weight='bold' align='left' variant='h5' className={styles.title}>
        {i18n('Our vision')}
      </Typography>
      <Typography weight='light' color='dark' align='left' variant='subtitle2'>
        {i18n('Our vision is to enable a safer, better, smarter future by making connected car services accessible for everyone.')}
      </Typography>
      <br/>
      <Typography weight='light' color='dark' align='left' variant='subtitle2'>
        {i18n('We aim to be the leading provider of connected car services. Thus, we will be progressively adding integrations to new smart platforms, applications, and other software services. Our commitment is to continuously add capabilities to the OBI+ platform over time and connect the leading technology of tomorrow.')}
      </Typography>
    </Grid>
  </Grid>
)