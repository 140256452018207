import React from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import i18n from '../../../locale';
import Button from '../../../components/Button';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <Grid item
    xs={12} sm={6} md={6} lg={6} xl={6}
    className={styles.partnerWrapper}>
    <Grid item container className={clsx(styles.partnerContainer, styles.becomePartnerContainer)}
      alignContent='center'
      alignItems='center'>
      <Grid item xs={12} sm={12} md={12} className={styles.textContainer}>
        <Typography variant='h5' color='secondary'
          align='center'
          weight='bold'
          className={styles.textBecomePartner}>
          {i18n('Want to become a partner?')}
        </Typography>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <a href='mailto:hello@obiplus.com' className={styles.anchor}
            rel="noopener noreferrer">
            <Button className={styles.buttonBecomePartner}>
              <Typography variant='subtitle2' color='white'
                align='center'>
                {i18n('Contact our business team')}
              </Typography>
            </Button>
          </a>
        </div>
      </Grid>
    </Grid>
  </Grid>
);