import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import Typography from '../../../components/Typography';
import SectionWrapper from '../../../components/SectionWrapper';
import Tile from './Tile';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        solution1: imageSharp(fluid: {originalName: {eq: "About-Us-LetsWork-1.png"}}) {
          fluid(maxWidth: 165, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        solution2: imageSharp(fluid: {originalName: {eq: "About-Us-LetsWork-2.png"}}) {
          fluid(maxWidth: 165, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        solution3: imageSharp(fluid: {originalName: {eq: "About-Us-LetsWork-3.png"}}) {
          fluid(maxWidth: 165, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        solution4: imageSharp(fluid: {originalName: {eq: "About-Us-LetsWork-4.png"}}) {
          fluid(maxWidth: 165) {
            ...GatsbyImageSharpFluid
          }
        }
        solution5: imageSharp(fluid: {originalName: {eq: "About-Us-LetsWork-5.png"}}) {
          fluid(maxWidth: 165) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ solution1, solution2, solution3, solution4, solution5 }) => (
      <SectionWrapper>
        <Grid container justify='center' alignContent='center' alignItems='center'
          className={styles.container}>
          <Grid item container xs={12} sm={12} md={12} lg={6} xl={6}
            alignContent='center'
            alignItems='center'
            justify='center'
            className={styles.callToActionContainer}>
            <Typography variant='h3'
              weight='bold'
              align='left'
              className={styles.titleContainer}>
              {i18n('Let\'s work together to make your project a reality')}
            </Typography>
            <Typography variant='subtitle2'
              weight='light'
              align='left'
              className={styles.subtitleContainer}>
              {i18n('We are already supporting a variety of commercial use cases and we continue to search for new ideas and concepts that drive better digital experiences. Let us know what you want to do.')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}
            className={styles.tilesContainer}>
            <Tile img={solution1}
              text={i18n('Remote vehicle management')}/>
            <Tile img={solution2}
              text={i18n('Usage-based services fueled by car data')}/>
            <Tile img={solution3}
              text={i18n('Innovative on-demand car-related services')}/>
            <Tile img={solution4}
              text={i18n('Unique driver assistant services')}/>
            <Tile img={solution5}
              text={i18n('Smart location-based services')}/>
          </Grid>
        </Grid>
      </SectionWrapper>
    )}/>
);