import React from "react";
import Layout from "../../components/Layout"
import SEO from "../../components/seo";
import Hero from './Hero';
import Vision from './Vision';
import DigitalWorld from './DigitalWorld';
import Partners from './Partners';
import WorkTogether from "./WorkTogether";
// import ContactUs from "./ContactUs";
import CallToAction from '../CallToAction';
import sitemap from '../../data/sitemap';

export default ({ seo, location }) => (
  <Layout overlay page={sitemap.aboutUs}
    url='_url:about-us'>
    <SEO {...seo} location={location}/>
    <Hero/>
    <Vision/>
    <DigitalWorld/>
    <Partners/>
    <WorkTogether/>
    {/* <ContactUs/> */}
    <CallToAction/>
  </Layout>
);