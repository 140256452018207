import React from 'react';
import { Grid } from '@material-ui/core';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} container justify='center' alignContent='center' alignItems='center'
    className={styles.wrapper}>
    <Grid item container className={styles.container}  justify='center' alignContent='center' direction='column'>
      <Typography weight='bold' align='left' variant='h5' className={styles.title}>
        {i18n('Who are we?')}
      </Typography>
      <Typography weight='light' color='dark' align='left' variant='subtitle2'>
        {i18n('OBI+ is a tech company offering a cloud-based platform that provides the infrastructure and digital products to make connected car services accessible to everyone.')}
      </Typography>
      <br/>
      <Typography weight='light' color='dark' align='left' variant='subtitle2'>
        {i18n('At its core, OBI+ is driven by a great team of engineers, designers, marketeers with a mission to empower innovation through a suite of services built to enable personalized digital experiences for every car driver, fleet operator, mechanic, and any other service provider who recognizes the potential for a safer, better, smarter future powered by data.')}
      </Typography>
    </Grid>
  </Grid>
)